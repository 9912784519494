<template>
    <div class="squareIndex-container">
        <el-carousel height="500px" arrow="never" trigger="click">
            <el-carousel-item v-for="item in balnner" :key="item.id">
                <div class="swiper">
                    <img :src="$util.host +item.url" alt="">
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="body">
            <div class="mian">
                <div class="item">
                    <span class="tit">商业资讯</span>
                    <div class="line"></div>
                    <ul>
                        <li v-for="item in businessList" :key="item.id" @click="information(item.url)">
                            <span class="title">{{item.title}}</span><span>{{item.createTime}}</span>
                        </li>
                    </ul>
                </div>
                <div class="item">
                    <span class="tit">资质动态</span>
                    <div class="line"></div>
                    <ul>
                        <li v-for="item in qualificationList" :key="item.id" @click="information(item.url)">
                            <span class="title">{{item.title}}</span><span>{{item.createTime}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="chat">
            <img src="../../assets/images/home/chat.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "squareIndex",
    data() {
        return {
            balnner: [],
            businessList: [],
            qualificationList: [],
        };
    },
    mounted() {
        this.getBalnner()
        this.allData()
    },
    methods: {
        allData() {
            this.$util.post('/dataSquare/centerList').then(res => {
                res.data.forEach(item => {
                    if (item.type == '1') {
                        this.businessList.push(item)
                    } else {
                        this.qualificationList.push(item)
                    }
                })

            })
        },
        information(url) {
            console.log(url);

            window.open(url, "_blank");
            // this.$router.push({
            //     path: '/information'
            // })
        },
        // 获取轮播图
        getBalnner() {
            this.$util.post('/home/banner', {
                type: '2',
                classifyId: 0
            }).then(res => {
                this.balnner = res.data
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.squareIndex-container {
    // width: 1920px;
}
.swiper {
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        height:100%;
    }
}
/deep/.el-carousel__button {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 100%;
    opacity: 1;
}
/deep/.el-carousel__indicator.is-active button {
    background: #e40012;
}
.body {
    border: 1px solid transparent;
    // width: 1920px;
    height: 687px;
    background: url("../../assets/images/square/bj.png");
    background-size: 100%;
    .mian {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        height: 485px;
        margin: auto;
        margin-top: 100px;
        .item {
            box-sizing: border-box;
            padding: 30px;
            width: 581px;
            height: 485px;
            background: #ffffff;

            li {
                display: flex;
                justify-content: space-between;
                list-style: none;
                margin-top: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #222222;
                cursor: pointer;
                .title {
                    display: block;
                    max-width: 250px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
            .tit {
                display: inline-block;
                font-size: 20px;
                font-weight: 400;
                color: #000000;
                margin-bottom: 8px;
            }
            .line {
                width: 521px;
                height: 0px;
                border: 1px solid #eeeeee;
                margin: auto;
            }
        }
    }
}
.chat {
    width: 97px;
    height: 103px;
    position: fixed;
    right: 0;
    z-index: 10000;
    top: 40%;
    img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}
</style>